import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import axios from 'axios';

function ProductListing() {
    const [formData, setFormData] = useState({
        productName: '',
        description: '',
        category: '',
        SKU: '',
        price: '',
        discount: '',
        stockQuantity: '',
        minOrderQuantity: '',
        shippingOption: '',
        images: []  // Store selected files here
    });

    const [radioValue, setRadioValue] = useState('11');
    const radios = [
        { name: 'Standard Shipping 5-6 Business Days', value: '11' },
        { name: 'Express Shipping 2-3 Business Days', value: '12' },
        { name: 'Overnight Shipping 1 Business Day', value: '13' },
        { name: '2 Days Delivery', value: '14' },
        { name: 'Same Day Delivery', value: '15' },
    ];

    const navigate = useNavigate(); // Initialize useNavigate for redirection

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, images: e.target.files });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'images') {
                for (let i = 0; i < formData.images.length; i++) {
                    data.append('images', formData.images[i]);
                }
            } else {
                data.append(key, formData[key]);
            }
        });

        try {
            const res = await axios.post('http://localhost:8080/api/auth/submit', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(res.data);
            alert('Product listed successfully!');

            // Redirect and pass formData to ProductSuccess
            navigate('/product-success', { state: { formData } }); // Replace '/success-page' with the desired route
        } catch (err) {
            console.error(err);
            alert('Error submitting the form.');
        }
    };

    return (
        <div className="productListing mt-1">
            <div className="container">
                <div className='container' style={{backgroundColor: '#B1B1B1', fontSize: '28px',  width: '100%', 
        margin: '0'}}>
            <h1>
                    List A NEW PRODUCT
                    </h1>
                </div>
                <div className="container row mt-4">
                    <div className="col-md-12">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Enter product details</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="productName"
                                    placeholder="Enter name of the product"
                                    onChange={handleChange}
                                />
                                <br />
                                <Form.Control
                                    type="text"
                                    name="description"
                                    placeholder="Description of the product"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="category"
                                    placeholder="Choose product Category"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="SKU"
                                    placeholder="Enter product SKU"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>Upload Product Images</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="images"
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </Form.Group>

                            <Form.Group>
                            <Form.Label>Set pricing in inventory</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="price"
                                    placeholder="Enter Selling Price in Rs"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="discount"
                                    placeholder="Discounts if applicable"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="stockQuantity"
                                    placeholder="Available Stock Quantity"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="minOrderQuantity"
                                    placeholder="Minimum Order Quantity if applicable"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <div>Shipping Options</div>
                            <ButtonGroup>
                                {radios.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant="outline-primary"
                                        name="shippingOption"
                                        value={radio.value}
                                        checked={radioValue === radio.value}
                                        onChange={(e) => {
                                            setRadioValue(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        style={{
                                            marginRight: '10px', // Add space between buttons
                                            backgroundColor: radioValue === radio.value ? '#00A9B4' : '', // Blue background for active button
                                            borderColor: radioValue === radio.value ? '#00A9B4' : '#000', // Blue background for active button
                                            color: radioValue === radio.value ? 'white' : '#000', // White text for active button
                                          }}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>

                            <Button type="submit" className="mt-5" style={{backgroundColor: '#FFD700', color: '#000', fontWeight: '700', padding: '10px', borderColor: '#FFD700', fontSize: '20px'}}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductListing;
