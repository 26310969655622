import logo from './logo.svg';
import './App.css';
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './AuthContext';

import MyNavbar from './components/customer/navbar'; // Customer Navbar
import AdminNavbar from './components/admin/navbar'; // Admin Navbar
import Landing from './components/customer/landing';
import About from './components/customer/about';
import Contact from './components/customer/contact';
import ProductListing from './components/admin/productlisting';
import ProductSuccess from './components/admin/productsuccess';
import Dashboard from './components/admin/dashboard';

// New component to determine which navbar to render
function Main() {
  const location = useLocation(); // Get the current location

  // Determine which navbar to use based on the current path
  const isAdminRoute = ['/dashboard', '/productlisting', '/product-success'].includes(location.pathname);

  return (
    <>
      {isAdminRoute ? <AdminNavbar /> : <MyNavbar />}
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path='/productlisting' element={<ProductListing />} />
        <Route path='/product-success' element={<ProductSuccess />} />
        {/* other routes */}
      </Routes>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <HashRouter>
        <Main /> {/* Render the Main component inside the HashRouter */}
      </HashRouter>
    </AuthProvider>
  );
}

export default App;
