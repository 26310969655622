import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaUserCircle } from "react-icons/fa";
import CloseButton from 'react-bootstrap/CloseButton'; // Import the CloseButton component
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import DashboardOverview from './dashboardoverview';
import VendorDetails from './vendordetails';
import OrderReceived from './orderreceived';
import InvoiceGeneration from './invoicegeneration';
import PaymentDetails from './paymentdetails';
import './dashboard.css';
import ProductListing from './productlisting';

function Dashboard() {
    const [show, setShow] = useState(true);
    const [menuOption, setMenuOption] = useState('1'); // State for selected toggle button

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

     // Define the toggle menu options
     const menuItems = [
        { name: 'Dashboard', value: '1' },
        { name: 'Vendor Details', value: '2' },
        { name: 'Order Received', value: '3' },
        { name: 'Invoice Generation', value: '4' },
        { name: 'Payment Details', value: '5' },
        { name: 'Add Products', value: '6' },
    ];
  
    return(
        <div>
            <div className='container row mt-5'>
                <div className='col-md-4'>
                    <Button  onClick={handleShow} style={{backgroundColor: '#00A9B4'}}>
                <FaUserCircle size={20}/> Admin
                    </Button>

            <       Offcanvas show={show} onHide={handleClose} className="offcanvas" style={{top: '97px', backgroundColor: '#545454'}}>
                        <Offcanvas.Header className="d-flex flex-column">
                            <div className="d-flex justify-content-start w-100">
                                <CloseButton aria-label="Hide" onClick={handleClose} />
                            </div>
                            <div className="d-flex justify-content-center w-100">
                                <Offcanvas.Title><FaUserCircle color='#FFF' size={30}/></Offcanvas.Title>
                            </div>
                            <div className="d-flex justify-content-center w-100">
                                <Offcanvas.Title>Welcome Admin</Offcanvas.Title>
                            </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                        <ButtonGroup vertical className="w-100">
                                {menuItems.map((item, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant="outline-primary"
                                        name="menuOption"
                                        value={item.value}
                                        checked={menuOption === item.value}
                                        onChange={(e) => setMenuOption(e.currentTarget.value)}
                                        style={{
                                            marginRight: '10px', // Add space between buttons
                                            marginTop: '10px',
                                            backgroundColor: menuOption === item.value ? '#00A9B4' : '#FFF', // Blue background for active button
                                            borderColor: menuOption === item.value ? '#00A9B4' : '#000', // Blue background for active button
                                            color: menuOption === item.value ? '#000' : '#000', // White text for active button
                                            borderRadius: '4px'
                                        }}
                                    >
                                        {item.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>

                            {/* Placeholder content based on selected menu item */}
                            
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
                <div className='col-md-8' style={{ height: '80vh', overflowY: 'auto' }}>
                <div className="mt-1">
                                {menuOption === '1' && <DashboardOverview/>}
                                {menuOption === '2' && <VendorDetails/>}
                                {menuOption === '3' && <OrderReceived/>}
                                {menuOption === '4' && <InvoiceGeneration/>}
                                {menuOption === '5' && <PaymentDetails/>}
                                {menuOption === '6' && <ProductListing/>}
                            </div>
                </div>
            </div>
        </div>
    )
};

export default Dashboard;