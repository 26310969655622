import SubNavbar from "./subnavbar";
import './landing.css';
import { useState } from "react";

function Landing() {
  // State to manage which text is active
  const [activeText, setActiveText] = useState('text1');

  // Function to toggle active text
  const handleToggle = (text) => {
    setActiveText(text);
  };

  return (
    <div className="landing">
      {/* SubNavbar */}
      <SubNavbar />
      
      {/* Banner */}
      <div className="banner-container">
        <img src="/assets/Banner (2).png" className="img-fluid" alt="Banner" />
      </div>

      {/* Text Toggle Area - Horizontally Aligned */}
      <div className="toggle_container">
        <div
          className={`toggle-text ${activeText === 'text1' ? 'active' : ''}`}
          onClick={() => handleToggle('text1')}
          style={{color: '#000', fontWeight: '600'}}
        >
          Featured
        </div>
        <div
          className={`toggle-text ${activeText === 'text2' ? 'active' : ''}`}
          onClick={() => handleToggle('text2')}
          style={{color: '#000', fontWeight: '600'}}
        >
          On Sale
        </div>
        <div
          className={`toggle-text ${activeText === 'text3' ? 'active' : ''}`}
          onClick={() => handleToggle('text3')}
          style={{color: '#000', fontWeight: '600'}}
        >
          Top Rated
        </div>
       
      </div>

      
    </div>
  );
}

export default Landing;
