import Table from 'react-bootstrap/Table';

function VendorDetails() {
    return(
        <div>
            <h1>Vendor Details</h1>
            <div className='mt-4'>
                <h6>Vendor Details</h6>
                <Table responsive bordered>
                    <thead>
                        <tr>
                        
                            <th>Vendor Name</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Edit</th>
                            <th>Block</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>
                        
                    </tbody>
                </Table>
            </div>

            <div className='mt-4'>
                <h6>Vendor Details</h6>
                <Table responsive bordered>
                    <thead>
                        <tr>
                        
                            <th>Vendor Name</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Edit</th>
                            <th>Block</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>
                        
                    </tbody>
                </Table>
            </div>

            <div className='mt-4'>
                <h6>Vendor Details</h6>
                <Table responsive bordered>
                    <thead>
                        <tr>
                        
                            <th>Vendor Name</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Edit</th>
                            <th>Block</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>

                        <tr>       
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>
                        
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default VendorDetails;