import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaHeart } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { IoSearch } from "react-icons/io5";

function SubNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-custom" style={{backgroundColor: '#008080'}}>
      <Container>
        {/* Logo on the left */}
        <Navbar.Brand href="/" style={{color: '#FFF'}}>
          Shop By Department<IoIosArrowForward/>
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* Centered Navigation Links */}
          <Nav className="mx-auto">
          <InputGroup >
        <InputGroup.Text id="basic-addon1"><IoSearch/></InputGroup.Text>
        <Form.Control
          placeholder="All Categories"
          aria-label="All Categories"
          aria-describedby="basic-addon1"
          fullWidth
        />
        <InputGroup.Text id="basic-addon2" style={{backgroundColor:"#FFD700"}}>Search</InputGroup.Text>
      </InputGroup>
          </Nav>
          {/* Right aligned links (e.g., Login) */}
          <Nav className="ms-auto">
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default SubNavbar;
