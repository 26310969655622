import React from 'react';
import { IoIosSettings } from "react-icons/io";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { IoNotificationsCircle } from "react-icons/io5";



function AdminNavbar() {

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-custom" style={{backgroundColor: '#F5F5F5', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}}>
      <Container>
        <Navbar.Brand href="/"><img src='/assets/logo.jpg' alt='' width={100} height={70} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
          <Nav.Link href="/" ><IoIosSettings size={30}/></Nav.Link>
            <Nav.Link href="/#/subscription" ><IoNotificationsCircle size={30}/></Nav.Link>
            
            <Nav.Link href="/#/toys" style={{border: '1px solid #008080', backgroundColor: '#008080', borderRadius: '12px', color: '#FFF'}}>Logout</Nav.Link>
           
      
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
