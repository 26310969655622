import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaHeart } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

function MyNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-custom" style={{backgroundColor: '#F5F5F5'}}>
      <Container>
        {/* Logo on the left */}
        <Navbar.Brand href="/">
          <img src="/assets/logo.jpg" alt="Logo" width={100} height={70} />
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* Centered Navigation Links */}
          <Nav className="mx-auto">
            <Nav.Link href="/" style={{color: '#000', fontWeight: '600'}}>Home</Nav.Link>
            <Nav.Link href="/#/about" style={{color: '#000', fontWeight: '600'}}>About Us</Nav.Link>
            <Nav.Link href="/#/contact" style={{color: '#000', fontWeight: '600'}}>Contact Us</Nav.Link>
            <Nav.Link href="/#/toys" style={{color: '#000', fontWeight: '600'}}>Blog</Nav.Link>
          </Nav>
          {/* Right aligned links (e.g., Login) */}
          <Nav className="ms-auto">
            <Nav.Link href="/" ><FaHeart style={{color: '#000', fontWeight: '600'}}/></Nav.Link>
            <Nav.Link href="/"><FaShoppingCart style={{color: '#000', fontWeight: '600'}}/></Nav.Link>
            <Nav.Link href="/"><FaUser style={{color: '#000', fontWeight: '600'}}/></Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
